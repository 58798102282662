<template>
  <vx-card v-if="calculator" :title="calculator.name">
    <form data-vv-scope="dabuenavida-loan-data" ref="form" @submit.prevent class="grid gap-8 md:grid-cols-2 grid-cols-1">
      <vx-card
          v-if="calculator"
          :beforeChange="validateDabuenavidaForm"
      >
        <div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <label :for="`termDataInterval`">Plazos disponibles (Meses)</label>
            </div>

            <div class="vx-col md:w-2/5 w-full md:pr-0">
              <vs-input
                id="termDataInterval"
                name="term-data-interval"
                v-model="calculator.settings.term_data.intervals"
                class="w-full"
                v-validate="'required|termDataInterval'"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`dabuenavida-loan-data.term-data-interval`) }}</span>
              <br />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label :for="`termDataInterval`">Pagos disponibles (meses)</label>
            </div>

            <div class="vx-col md:w-1/3 w-full md:pr-0">
              <label :for="`min-loan-amount`" class="w-full select-large">Min.</label>
              <vs-input
                id="paymentsIntervalMin"
                name="payments-interval-min"
                v-model="calculator.settings.payments.min"
                class="w-full"
                v-validate="`required|max_value:${calculator.settings.payments.max}`"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`dabuenavida-loan-data.payments-interval-min`) }}</span>
              <br />
            </div>

            <div class="vx-col md:w-1/3 w-full md:pr-0">
              <label :for="`min-loan-amount`" class="w-full select-large">Max.</label>
              <vs-input
                id="paymentsIntervalMax"
                name="payments-interval-max"
                v-model="calculator.settings.payments.max"
                class="w-full"
                v-validate="`required|min_value:${calculator.settings.payments.min}`"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`dabuenavida-loan-data.payments-interval-max`) }}</span>
              <br />
            </div>
          </div>

          <div class="vx-col w-2/5">
            <label for="rate" class="w-full select-large">Tasa</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              id="rate"
              name="rate"
              v-model="calculator.settings.rate"
              class="w-full"
              v-validate="'required|decimal:2|min_value:1|max_value:100'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`dabuenavida-loan-data.rate`) }}</span>
            <br />
          </div>

          <div class="vx-col w-2/5">
            <label for="rate" class="w-full select-large">Máxima tasa retirable</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              id="maxWithdrawalRate"
              name="max-withdrawal-rate"
              v-model="calculator.settings.maxWithdrawalRate"
              class="w-full"
              v-validate="'required|decimal:2|min_value:1|max_value:100'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`dabuenavida-loan-data.max-withdrawal-rate`) }}</span>
            <br />
          </div>
        </div>
      </vx-card>
    </form>

    <div class="flex justify-end mt-5">
      <vs-button
        v-if="$can('update', 'calculator')"
        size="small"
        icon-pack="feather"
        icon="icon-save"
        @click="updateCalculator()"
        class="ml-4"
      >
        Guardar cambios
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
  import { Validator } from "vee-validate";

  import { cloneDeep } from "lodash";

  import CoreClient from "@/utils/coreClient";
  import Notifier from "@/utils/notification";
  import { termDataInterval } from "@/utils/customValidations";

  Validator.extend("termDataInterval", termDataInterval);

  const calculatorsClient = new CoreClient("calculators");
  let notifier = new Notifier();

  Validator.localize({
    en: {
      messages: {
        required: "Este campo es requerido",
        decimal: (_, decimals) => `El campo debe ser numérico y puede contener hasta ${decimals} puntos decimales`,
        min_value: (_, min) => `El campo no debe ser menor a: ${min}`,
        max_value: (_, max) => `El campo no debe ser mayor a: ${max}`,
      },
    },
  });

  export default {
    name: "PersonalCalculator",

    data() {
      return {
        calculator: null,
      };
    },

    mounted() {
      this.$vs.loading();

      this.getCalculator(false).then(() => {
        this.$vs.loading.close();
      });
    },

    methods: {
      buildData(settings) {
        settings.term_data.intervals = [
          ...new Set(settings.term_data.intervals.split(",").map(Number)),
        ].sort((a, b) => a - b);

        settings.payments_data.intervals = Array.from({ length: settings.payments.max - settings.payments.min + 1 }, (_, i) => settings.payments.min + i);
        settings["rates_by_currency"] = {
          "HNL": [
            {
              rate: Number(settings.rate),
              conditions: {}
            }
          ]
        }

        settings["max_withdrawal_rate"] = {
          "HNL": [
            {
              rate: Number(settings.maxWithdrawalRate),
              conditions: {}
            }
          ]
        }

        return {
          name: this.calculator.name,
          settings: {
            term_data: settings.term_data,
            payments_data: settings.payments_data,
            max_withdrawal_rate: settings.max_withdrawal_rate,
            rates_by_currency: settings.rates_by_currency,
          }
        };
      },

      async updateCalculator() {
        this.validateDabuenavidaForm()
          .then(async (response) => {
            if (response) {
              this.$vs.loading();

              await calculatorsClient.updateCalculator({
                pathParameters: { id: this.calculator.id },
                data: this.buildData(cloneDeep(this.calculator.settings)),
              })
                .then(() => {
                  this.$vs.loading.close();
                  notifier.notification("created");
                  this.getCalculator();
                })
                .catch(() => {
                  this.$vs.loading.close();
                  notifier.alertMessage("error");
                });
            }
          })
          .catch(() => {
            return;
          });
      },

      async getCalculator(showLoading = true) {
        if (showLoading) {
          this.$vs.loading();
        }

        await calculatorsClient.readCalculator({ pathParameters: { id: this.$route.params.id } })
          .then((response) => {
            if (showLoading) {
              this.$vs.loading.close();
            }

            this.calculator = response.data;

            this.prepareData();
          })
          .catch(() => {
            if (showLoading) {
              this.$vs.loading.close();
            }
            notifier.notification("error");
          });
      },

      prepareData() {
        this.calculator.settings.term_data.intervals = this.calculator.settings.term_data.intervals.join(", ");

        let payments = {
          min: this.calculator.settings.payments_data.intervals[0],
          max: this.calculator.settings.payments_data.intervals.at(-1),
        }

        this.$set(this.calculator.settings, "payments", payments);

        let ratesByCurrency = [];
        let withdrawalRatesByCurrency = [];

        for (const currency in this.calculator.settings.rates_by_currency) {
          ratesByCurrency.push({
            currency: currency,
            rates: this.calculator.settings.rates_by_currency[currency],
          });
        }

        this.$set(this.calculator.settings, "rate", ratesByCurrency[0].rates[0].rate);

        for (const currency in this.calculator.settings.max_withdrawal_rate) {
          withdrawalRatesByCurrency.push({
            currency: currency,
            rates: this.calculator.settings.max_withdrawal_rate[currency],
          });
        }

        this.$set(this.calculator.settings, "maxWithdrawalRate", withdrawalRatesByCurrency[0].rates[0].rate);
      },

      validateDabuenavidaForm() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("dabuenavida-loan-data").then((result) => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          });
        });
      },
    }
  }
</script>
